<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-04-16 13:30:01
 * @LastEditTime: 2021-07-22 14:50:34
 * @LastEditors: huacong
-->
<template>
  <!-- 发布求职 -->
  <div class="contentBox">
    <stepTwo></stepTwo>
    <div style="height: 50px"></div>
    <applyJob ref="applyJob"></applyJob>
    <section>
      <div class="text-center">
        <el-button
          type="primary"
          class="radius-25"
          style="width:200px"
          v-prevent-repeat-click
          @click="jobPost"
          >提交</el-button
        >
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import stepTwo from "@/components/stepTwo/stepTwo";
import applyJob from "@/view/comPage/postJob/applyJob";
export default {
  components: {
    stepTwo,
    applyJob,
  },
  computed: {
    ...mapState([]),
    // ...mapState(["comRealeInfo", "comStaffInfo"]),
    comRealeInfo() {
      let k = sessionStorage.getItem("comRealeInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(sessionStorage.getItem("comRealeInfo"));
    },
    comStaffInfo() {
      let k = sessionStorage.getItem("comStaffInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(sessionStorage.getItem("comStaffInfo"));
    },
  },
  methods: {
    ...mapMutations(["changePostReturnInfo"]),
    jobPost() {
      let info = this.$refs.applyJob.postInfo;
      info.flag = 2;
      info.com_id = this.comRealeInfo.id;
      if (this.$refs.applyJob.cerList.length === 3) {
        info.certificate = this.$refs.applyJob.cerList[0];
        info.major = this.$refs.applyJob.cerList[1];
        info.leve = this.$refs.applyJob.cerList[2];
      } else if (this.$refs.applyJob.cerList.length === 2) {
        info.certificate = this.$refs.applyJob.cerList[0];
        info.major = this.$refs.applyJob.cerList[1];
        info.leve = "";
      }
      info.expected_type = this.$refs.applyJob.jobList[0];
      info.expected_major = this.$refs.applyJob.jobList[1];
      if (this.$refs.applyJob.tjzw) {
        info.is_tj = 1;
      } else {
        info.is_tj = 0;
      }
      info.zc_need_name = this.$refs.applyJob.postList[0];
      info.zc_need_major = this.$refs.applyJob.postList[1];

      this.$http.post("company/index/publish", info).then((res) => {
        if (res.data.resultCode === 1) {
          this.changePostReturnInfo({
            postReturnInfo: res.data.resultData,
          });
          this.$router.push("postThree");
        }
      });
    },
  },
  mounted() {},
};
</script>
